





















































































































































import { Validator } from '@/constant/Mixins';
import Component from 'vue-class-component';
import { Mixins, Prop, Watch } from 'vue-property-decorator';
import filters from '@/constant/Filters';
import { Action, State } from 'vuex-class';
import { ServerResponse } from '@/services/response.types';
import { ProductDataModel, ProductModel, ProductSummaryModel } from '@/store/product/types';
import { FormCompareKesehatanModel } from '@/store/product/kesehatan/types';
import { BSelectOption } from '@/store/types';
import { PRODUCT_INSTALLMENT_MONTH_DIFF } from '@/constant/Enums';

interface TableItem {
  id: number;
  name: string;
  total: string;
  price: string;
  quotation_id: string;
  features: {
    death_benefit: number;
    medical_fee: number;
    funeral_fee: number;
  };
  brand: string;
  _showDetails: true;
}

@Component({
  name: 'listKecelakaanDiri',
  components: {
    formKecelakaanDiri: () => import('@/components/formCompare/comparePersonalAccident.vue'),
    ProductDetail: () => import('@/components/ProductDetail.vue'),
    callMe: () => import('@/components/FormCallMe.vue'),
  },
  filters,
})
export default class listKecelakaanDiri extends Mixins(Validator) {
  @Action('CompareAction', { namespace: 'product/kecelakaandiri' }) CompareAction!: (params?: any) => Promise<ServerResponse>;
  @Action('GetSummary', { namespace: 'product/kecelakaandiri' }) GetSummary!: (quotation_id: string, params?: any) => Promise<ServerResponse>;

  @State('onLoad', { namespace: 'product/kecelakaandiri' }) onLoad!: boolean;
  @State('data', { namespace: 'product/kecelakaandiri' }) data!: ProductDataModel;
  @State('form', { namespace: 'product/kecelakaandiri' }) form!: FormCompareKesehatanModel;
  @State('summary', { namespace: 'product/kecelakaandiri' }) summary!: ProductSummaryModel;
  @State('participant_type', { namespace: 'product/kecelakaandiri' }) participant_type!: BSelectOption[];

  @Watch('OnComparing') OnComparingChange(value: boolean) {
    if (value) document.body.classList.add('overflow-hidden');
    else document.body.classList.remove('overflow-hidden');
  }

  public compareLimit = 3;
  public comparisons: TableItem[] = [];
  public selected: TableItem = null;

  public get fieldsCompare(): any[] {
    let fields = ['label'];
    this.comparisons.forEach((item, i) => fields.push(`produk_${i + 1}`));
    return fields;
  }

  public get comparisonsAside(): { label: string; product1?: TableItem; product2?: TableItem; product3?: TableItem; }[] {
    let rows = [
      { label: 'Label', key_ref: ['brand&provider_name'] },
      { label: 'Produk', key_ref: ['name'] },
      { label: 'Harga Motor', key_ref: ['sum_insured'] },
      { label: 'Harga Premi', key_ref: ['installment', [2], 'nominal'] },
      // { label: 'Rating', key_ref: ['rating'] },
      { label: 'Tipe Asuransi', key_ref: ['type_insurance'] },
      { label: 'Rawat Jalan', key_ref: ['features', 'rawat_jalan'] },
      { label: 'Rawat Inap', key_ref: ['features', 'rawat_inap'] },
      { label: 'Rawat Bedah', key_ref: ['features', 'rawat_bedah'] },
      { label: 'Fitur', key_ref: [] },
      { label: 'Perlindungan Tambahan', key_ref: [] },
      { label: 'Action', key_ref: [] },
    ].map((cols: any) => {
      this.comparisons.forEach((item, i) => (cols[`product_${i + 1}`] = item));
      return cols;
    });

    return rows;
  }

  public showDetail = false;
  public showCallMe = false;
  public OnComparing = false;

  public SortByOptions = [
    {
      text: 'The Lowest',
      value: 1,
    },
    {
      text: 'The Highest',
      value: 2,
    },
    {
      text: 'Company A-Z',
      value: 3,
    },
    {
      text: 'Company Z-A',
      value: 4,
    },
  ];

  public fields = [
    {
      class: 'col-3',
      key: 'brand',
      label: 'Annual Price',
      sortable: false,
      _showDetails: true,
    },
    {
      class: 'col-3',
      key: 'price',
      label: 'Price',
    },
    {
      class: 'col-3',
      key: 'features',
      label: 'Insurance Features and Extra',
      sortable: false,
      headspan: [
        {
          label: 'Death Benefit',
        },
        {
          label: 'Medical Fee',
        },
        {
          label: 'Funeral Fee',
        },
      ],
    },
  ];

  public get items(): TableItem[] {
    // let type_insurance = this.participant_type.filter((item) => Number(item.value) === Number(this.form.participant_status))[0]?.text;
    return (
      this.data.listdata.map((item) => {
        return {
          id: Number(item.product_id),
          name: item.product_name,
          total: '~',
          price: String(item.total_price),
          quotation_id: item.quotation_id,
          features: {
            death_benefit: Number(item.col1),
            medical_fee: Number(item.col2),
            funeral_fee: Number(item.col3),
          },
          brand: item.logo,
          _showDetails: true,
        };
      }) ?? []
    );
  }

  public created(): void { }
  public mounted(): void {
    window.onresize = () => {
      if (this.comparisons.length > 0) this.OnCollapseShow(`compare-product-img`);
      if (this.OnComparing) this.OnCollapseShow(`comparer-product-img`);
    };
  }

  /**
   * OnCompare
   */
  public OnCompare(item: any) {
    this.comparisons.push(item);
  }

  /**
   * OnDeleteCompare
   */
  public OnDeleteCompare(item: any) {
    this.comparisons = this.comparisons.filter((v) => v.id !== item.id);
  }

  /**
   * GetRefferenceCompateAttribute
   */
  public GetRefferenceCompateAttribute(item: { label: string; key_ref: any[]; product_1?: TableItem; product_2?: TableItem; product_3?: TableItem; }, product_ref: string) {
    let value: any = item[product_ref];
    item.key_ref.forEach((key) => {
      if (key instanceof Array) value = value[key[0]];
      else if (typeof key === 'string') {
        if (key.indexOf('&') >= 0) {
          value = '';
          key.split('&').forEach((k, i) => {
            let refVal: string = item[product_ref];
            refVal = refVal[k] || '';

            let isHTML = refVal.indexOf('http') >= 0;
            if (isHTML) value += `<img ref="product-logo" src="${refVal}" class="img-thumbnail d-block mx-auto" style="height: 100px;" />`;
            else value += `<div class="h6 bold">${refVal}</div>`;
          });
        } else value = value[key];
      }
    });

    if (typeof value === 'boolean') return value ? `<i class="fa fa-check h4 text-success" aria-hidden="true"></i>` : `<i class="fa fa-times h4 text-danger" aria-hidden="true"></i>`;
    else if (Number(value) >= 1000) return this.formatCurrency(value);
    else return item.key_ref.length > 0 ? value : null;
  }

  /**
   * GetDetailSummary
   */
  public GetDetailSummary(item: ProductModel) {
    this.GetSummary(item.quotation_id).then((res) => {
      if (res.data) this.showDetail = true;
    });
  }

  /**
   * onClickCallMe
   */
  public onClickCallMe(item: TableItem) {
    this.selected = item;
    this.showCallMe = !this.showCallMe;
  }

  /**
   * OnCollapseShow
   */
  public OnCollapseShow(target: string) {
    let imgs = this.$refs[target] as HTMLImageElement[];
    let height = imgs[imgs.length - 1].clientHeight;
    imgs.forEach((img: HTMLImageElement) => {
      img.style.height = `${height}px`;
    });
  }
}
